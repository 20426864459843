import React, { useEffect, useState } from "react"
import { Button, Input, Spinner } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AuthService from "../../services/auth"
import EditModal from "../../components/common/modals/edit"
import PasswordInput from "../../components/common/forms/password"
import { NotificationId, setNotificationAction } from "../../hasura/slices/notification"
import { setAccessTokenAction, setAuthorizationErrorMessageAction, userSelector, UserState } from "../../hasura/slices/user"
import { QueryName } from "../../hasura/queryNames"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

// @ts-ignore
import googleIcon from "../../lib/images/google.svg"

export default function Login() {
  const dispatch = useDispatch()

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)

  const { authorizationErrorMessage, isQuerying }: UserState = useSelector(userSelector)

  useEffect(() => {
    dispatch(setAuthorizationErrorMessageAction(undefined))
  }, [email, password])

  useEffect(() => {
    if (authorizationErrorMessage) setIsLoggingIn(false)
  }, [authorizationErrorMessage])

  const logIn = () => {
    setIsLoggingIn(true)

    new AuthService().logIn(email!, password!, (accessToken?: string, error?: string) => {
      if (accessToken) {
        dispatch(setAccessTokenAction(accessToken))
      } else if (error) {
        dispatch(setAuthorizationErrorMessageAction(error))
      }

      setIsLoggingIn(false)
    })
  }

  const googleLogIn = () => {
    setIsLoggingIn(true)

    new AuthService().google(undefined, undefined, (accessToken, error) => {
      if (accessToken) {
        dispatch(setAccessTokenAction(accessToken))
        navigate("/home")
      } else {
        dispatch(setAuthorizationErrorMessageAction(error))
      }

      setIsLoggingIn(false)
    })
  }

  const sendResetPasswordEmail = (email: string) => {
    new AuthService().sendResetPasswordEmail(email)
    dispatch(setNotificationAction(NotificationId.ResetPasswordEmailSent))
    setForgotPassword(false)
  }

  return (
    <Layout>
      <SEO title="Sign Up" />

      <div className="d-flex flex-column align-items-center pt-4">
        <h1 className="bold text-xxl text--primary mb-0">Login to Wordcraft</h1>

        <div className="width-300px mt-4">
          <EditModal
            body="IMPORTANT: Students should ask your teacher for your password. Teachers who signed up with email & password (not Google) enter your email below to reset your password."
            confirmText="Send reset password email"
            disabled={false}
            isEditing={forgotPassword}
            placeholder="Email"
            save={sendResetPasswordEmail}
            setIsEditing={setForgotPassword}
            title="Forgot password?"
          />

          <Input
            autoCapitalize="none"
            autoFocus
            bsSize="lg"
            className="data-hj-allow my-2 w-100"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email or username"
            value={email || ""}
          />

          <PasswordInput password={password} setPassword={setPassword} />

          {isLoggingIn || isQuerying[QueryName.FetchUser] ? (
            <div className="flex-center">
              <Spinner className="my-2" size="lg" color="primary" />
            </div>
          ) : (
            <div className="text-center">
              <Button disabled={!email || !password} onClick={logIn} size="lg" className="w-100" color="primary">
                Log in
              </Button>

              <p className="my-1 text-muted text-m">or</p>

              <Button
                active={false}
                className="button-no-hover d-flex align-items-center justify-content-center w-100"
                color="primary"
                onClick={googleLogIn}
                outline
                size="lg"
              >
                <img className="icon-s mr-2" src={googleIcon} />
                Log in with Google
              </Button>

              <p onClick={() => setForgotPassword(true)} className="text-link text-s mb-2 mt-3">
                Forgot password?
              </p>

              <p onClick={() => navigate("/signup")} className="text-link text-s mb-0 w-100">
                Don't have an account?
              </p>

              {authorizationErrorMessage && <p className="error mt-2">{authorizationErrorMessage}</p>}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
